import React, {useState, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import Counter from '../../components/counter';
import Footer from '../../components/footer';
import MobileApp from '../../components/mobile-app';
import Navbar from '../../components/navbar';
import Switcher from '../../components/switcher';
import { useNavigate } from 'react-router-dom';
import ScrollToTop from '../../components/scroll-to-top';
import { REACT_SOCALII_VENDOR } from '../../constants/enviroment';
import { useAppContext } from '../../context/AppContext';
import { onCalculateSum, onCalculateTaxes } from '../../utils';
import useGetApi from "../../api/useGetApi";
import axios from 'axios';
import toast from "react-hot-toast";
import API from "../../api/API";
export default function Shopcart(props) {
  const { id } = useParams();
  const {
    state: { lang, addToCart, user },
    onRemoveCard,
  } = useAppContext();
  const navigate = useNavigate();
  const [discountCode, setDiscountCode] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountError, setDiscountError] = useState('');
  const subTotal = onCalculateSum(addToCart);
  const tax = onCalculateTaxes(subTotal, 5);
  const total = Number(subTotal) + Number(tax) - discountAmount;
  const token = localStorage.getItem('token_link_card');
  useEffect(()=>{
    localStorage.removeItem('DiscountAmount');
  },[])
  const handleApplyDiscount = async () => {
    if (!discountCode) {
      setDiscountError("Please enter a discount code.");
      toast.error("Please enter a discount code.");
      return;
    }
    try {
      const res = await API.get(
          `/users/get-coupans-validity/userId/${user?._id}/discountCode/${discountCode}`
      );
      if (res?.data?.status === "error") {
        const errorMessage = res?.data?.message;
        setDiscountError(errorMessage);
        toast.error(` ${errorMessage}`);
        setDiscountAmount(0);
        return;
      }
      // Extract discount details
      const verifyCoupanCode = res?.data?.data?.verifyCoupanCodeName;
      if (!verifyCoupanCode) {
        setDiscountAmount(0);
        setDiscountError("Invalid or expired discount code.");
        toast.error("Invalid or expired discount code.");
        return;
      }
      const discountValue = verifyCoupanCode?.discountValue || 0;
      const discountType = verifyCoupanCode?.discountType;
      console.log("Extracted Discount Value:", discountValue);
      console.log("Extracted Discount Type:", discountType);
      let calculatedDiscount = 0;
      if (discountType?.toLowerCase() === "percentage") {
        calculatedDiscount = (discountValue / 100) * subTotal;
      } else if (
          discountType?.toLowerCase() === "fixedamount" ||
          discountType?.toLowerCase() === "fixed"
      ) {
        calculatedDiscount = discountValue;
      }
      console.log("Final Calculated Discount:", calculatedDiscount);
      setDiscountAmount(calculatedDiscount);
      localStorage.setItem('DiscountAmount', calculatedDiscount);
      setDiscountError("");
      // Show success notification
      toast.success(" Coupon applied successfully!");
    } catch (error) {
      setDiscountAmount(0);
      const errorMessage = error?.response?.data?.message || "❌ Coupon verification failed. Please try again.";
      setDiscountError(errorMessage);
      toast.error(errorMessage);
      console.error("Discount code error:", error);
    }
  };
  return (
      <>
        <Navbar navClass="defaultscroll is-sticky" />
        <section className="relative table w-full py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800">
          <div className="container relative">
            <div className="grid grid-cols-1 mt-14">
              <h3 className="text-3xl leading-normal font-semibold">Fashion</h3>
            </div>
            <div className="relative mt-3">
              <ul className="tracking-[0.5px] mb-0 inline-block">
                <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500">
                  <Link to="/">Shop</Link>
                </li>
                <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180">
                  <i className="mdi mdi-chevron-right"></i>
                </li>
                <li
                    className="inline-block uppercase text-[13px] font-bold text-orange-500"
                    aria-current="page"
                >
                  SHOPCART
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="relative md:py-24 py-16">
          <div className="container relative">
            <div className="grid lg:grid-cols-1">
              <div className="relative overflow-x-auto shadow dark:shadow-gray-800 rounded-md">
                <table className="w-full text-start">
                  <thead className="text-sm uppercase bg-slate-50 dark:bg-slate-800">
                  <tr>
                    <th scope="col" className="p-4 w-4"></th>
                    <th scope="col" className="text-start p-4 min-w-[220px]">
                      Product
                    </th>
                    <th scope="col" className="p-4 w-24 min-w-[100px]">
                      Price
                    </th>
                    <th scope="col" className="p-4 w-56 min-w-[220px]">
                      Qty
                    </th>
                    <th scope="col" className="p-4 w-24 min-w-[100px]">
                      Total($)
                    </th>
                  </tr>
                  </thead>
                  {/* crete  debut view for stringify */}
                  {/* crete  end view for stringify */}
                  <tbody>
                  {addToCart?.map((item) => {
                    return (
                        <tr
                            className="bg-white dark:bg-slate-900 border-t border-gray-100 dark:border-gray-800"
                            key={item?.id}
                        >
                          <td className="p-4">
                            <button onClick={() => onRemoveCard(item?.id)}>
                              <i className="mdi mdi-window-close text-red-600"></i>
                            </button>
                          </td>
                          <td className="p-4">
                          <span className="flex items-center">
                            <img
                                src={item?.image}
                                className="rounded shadow dark:shadow-gray-800 w-12"
                                alt=""
                            />
                            <span className="ms-3">
                              <span className="block font-semibold">
                                {item?.title?.[lang]}
                              </span>
                            </span>
                          </span>
                          </td>
                          <td className="p-4 text-center">($) {item?.price}</td>
                          <td className="p-4 text-center">
                            <Counter qtn={item?.quantity} id={item?.id}/>
                          </td>
                          <td className="p-4  text-end">
                            ($) {item?.price * item?.quantity}
                          </td>
                        </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
              <div className="mt-6">
                <label className="block font-semibold mb-2">Discount Code</label>
                <div className="flex">
                  <input
                      type="text"
                      className="p-2 border rounded-md w-full"
                      value={discountCode}
                      onChange={(e) => setDiscountCode(e.target.value)}
                  />
                  <button
                      onClick={handleApplyDiscount}
                      className="ml-2 py-2 px-4 bg-blue-500 text-white rounded-md"
                  >
                    Apply
                  </button>
                </div>
                {discountError && <p className="text-red-500 mt-2">{discountError}</p>}
              </div>
              <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                <div className="lg:col-span-9 md:order-1 order-3">
                  <div className="space-x-1">
                    {token ? (
                        <Link
                            // onClick={() =>
                            //   navigate('/shop-checkout', { state: { orderList } })
                            // }
                            to="/shop-checkout"
                            className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center bg-green-500 text-white rounded-md mt-2"
                        >
                          Checkout
                        </Link>
                    ) : (
                        <>
                          <h2
                              style={{
                                color: '#333',
                                margin: '20px 0',
                              }}
                          >
                            Please log in or register if you don't have an account
                          </h2>
                          <button
                              onClick={() => {
                                window.location.href = `${REACT_SOCALII_VENDOR}/login?source=shop&&redirect=${window.location.href}`;
                              }}
                              className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center bg-green-500 text-white rounded-md mt-2"
                          >
                            Login
                          </button>
                          <button
                              onClick={() =>
                                  (window.location.href = `${REACT_SOCALII_VENDOR}/register?source=shop&&redirect=${window.location.href}`)
                              }
                              className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center bg-green-500 text-white rounded-md mt-2"
                          >
                            Signup
                          </button>
                        </>
                    )}
                    {/* <Link
                    to=""
                    className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center rounded-md bg-green-500/5 hover:bg-green-500 text-green-500 hover:text-white mt-2"
                  >
                    Add to Cart
                  </Link> */}
                  </div>
                </div>
                <div className="lg:col-span-3 md:order-2 order-1">
                  <ul className="list-none shadow dark:shadow-gray-800 rounded-md">
                    <li className="flex justify-between p-4">
                      <span className="font-semibold text-lg">Subtotal :</span>
                      <span className="text-slate-400">($) {subTotal}</span>
                    </li>
                    <li className="flex justify-between p-4 border-t border-gray-100 dark:border-gray-800">
                      <span className="font-semibold text-lg">Taxes :</span>
                      <span className="text-slate-400">($) {tax}</span>
                    </li>
                    {discountAmount > 0 && (
                        <li className="flex justify-between p-4 border-t border-gray-100 dark:border-gray-800 text-green-500">
                          <span className="font-semibold text-lg">Discount :</span>
                          <span>- ($) {discountAmount}</span>
                        </li>
                    )}
                    {/*{Number(discountAmount) > 0 && (*/}
                    {/*    <li className="flex justify-between p-4 border-t border-gray-100 dark:border-gray-800 text-green-500">*/}
                    {/*      <span className="font-semibold text-lg">Discount :</span>*/}
                    {/*      <span>- ($) {Number(discountAmount).toFixed(2)}</span>*/}
                    {/*    </li>*/}
                    {/*)}*/}
                    <li className="flex justify-between font-semibold p-4 border-t border-gray-200 dark:border-gray-600">
                      <span className="font-semibold text-lg">Total :</span>
                      <span className="font-semibold">($) {total}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <MobileApp/>
        </section>
        <Footer/>
        <Switcher/>
        <ScrollToTop/>
      </>
  );
}
